<template>
  <div class="tip-jar" ref="tipjar">
    <div class="venmo" @click="$emit('venmo')">
      <img class="logo" :src="require('@/assets/venmo.svg')" />
    </div>
    <div class="paypal" @click="openPaypalWindow">
      <img class="logo" :src="require('@/assets/paypal.svg')" />
    </div>
    <div class="stripe">Tip Your Host</div>
  </div>
</template>

<script>
import Vue from "vue"

const TIP_JAR_BOUNCE_INTERVAL = 10000

export default Vue.extend({
  name: "TipJar",
  props: {
    data: {
      /** @type {import('vue').PropType<{ paypal: string, venmo: string }>} */
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attentionInterval: 0,
      attentionTimeoutStack: []
    }
  },
  computed: {
    paypalUrl() {
      const { paypal } = this.data

      return paypal ? `//paypal.me/${paypal}` : undefined
    }
  },
  mounted() {
    this.attentionInterval = setInterval(
      this.bringAttention,
      TIP_JAR_BOUNCE_INTERVAL
    )
  },
  beforeDestroy() {
    this.attentionTimeoutStack.forEach(timeout => clearTimeout(timeout))
    this.attentionTimeoutStack = []
    clearInterval(this.attentionInterval)
  },
  methods: {
    openPaypalWindow() {
      window.open(this.paypalUrl, "Paypal", "height=600,width=600")
    },
    bringAttention() {
      if (this.$refs.tipjar) {
        this.$refs.tipjar.classList.add("appeared")
        this.$refs.tipjar.classList.add("bring-attention")
        const timeout = setTimeout(() => {
          if (this.$refs.tipjar)
            this.$refs.tipjar.classList.remove("bring-attention")
        }, 4000)
        this.attentionTimeoutStack.push(timeout)
      }
    }
  }
})
</script>

<style lang="scss">
.mode-mapper {
  .tip-jar {
    position: absolute;
    top: -20px;
    right: 0;
    border-radius: 50%;
    border: solid 3px $primary_accent_color;
    overflow: hidden;
    background: $color-black;
    transition: none;
    &.meetingmode {
      top: 90px;
    }
    .venmo {
      position: absolute;
      left: 0.01px;
      top: 0.01px;
      width: 99.98%;
      height: 50%;
      background: #4696d2;
      animation-duration: 0.5s;
      animation-name: bgSlide;
      animation-fill-mode: forwards;
      cursor: pointer;
      .logo {
        width: 25px;
        height: 25px;
        margin-top: -14px;
        margin-left: -12px;
        animation-name: venmoPop;
      }
    }
    .paypal {
      position: absolute;
      left: 0.01px;
      bottom: 0.01px;
      width: 99.98%;
      height: 50%;
      background: $color-white;
      animation-duration: 0.5s;
      animation-name: bgSlide;
      animation-fill-mode: forwards;
      cursor: pointer;
      .logo {
        width: 30px;
        height: 30px;
        margin-top: -12px;
        margin-left: -13px;
        animation-name: paypalPop;
      }
    }
    .stripe {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      color: transparent;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
      font-style: italic;
      text-align: center;
      background: $primary_accent_color;
      animation-duration: 1.5s;
      animation-name: stripeSlide;
      animation-fill-mode: forwards;
      transform: translate(-100%, -50%);
      cursor: default;
    }
    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      animation-delay: 0s;
      animation-duration: 3.5s;
      animation-fill-mode: forwards;
      transform: scale(0);
    }
    &.appeared {
      .stripe {
        color: $color-white;
        transform: translate(0, -50%);
        animation: none;
      }
      .logo {
        transform: scale(1);
        animation: none;
      }
    }
    &.bring-attention {
      animation-delay: 0.5s;
      animation-duration: 1s;
      animation-name: tipjarAttention;
      animation-fill-mode: forwards;
      .stripe {
        animation-delay: 0s;
        animation-duration: 2.75s;
        animation-name: stripeAttention;
        animation-fill-mode: forwards;
      }
      .logo {
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }
      .venmo .logo {
        animation-duration: 3.5s;
        animation-name: venmoAttention;
      }
      .paypal .logo {
        animation-duration: 4s;
        animation-name: paypalAttention;
      }
    }
  }
  .venmo-code {
    position: fixed;
    width: 130vw;
    height: 130vh;
    z-index: 100;
    left: 0;
    top: 0;
    .box {
      position: fixed;
      width: 300px;
      background: $color-primary-dark;
      border: solid 2px $primary_accent_color;
      border-radius: 10px;
      left: 50vw;
      top: 50vh;
      padding: 20px;
      cursor: pointer;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .text {
      font-weight: 600;
      text-transform: uppercase;
      color: $color-white;
      font-size: 17px;
      margin-bottom: 20px;
    }
    img {
      width: 175px;
    }
  }
}
@keyframes bgSlide {
  0% {
    height: 0;
  }
  100% {
    height: 50%;
  }
}
@keyframes stripeSlide {
  25% {
    transform: translate(-100%, -50%);
  }
  75% {
    transform: translate(0, -50%);
    color: transparent;
  }
  100% {
    transform: translate(0, -50%);
    color: $color-white;
  }
}
@keyframes venmoPop {
  0% {
    transform: scale(0);
  }
  14% {
    transform: scale(1.2);
  }
  28% {
    transform: scale(1);
  }
  51% {
    transform: scale(1);
  }
  58% {
    transform: scale(1.3);
  }
  72% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes paypalPop {
  0% {
    transform: scale(0);
  }
  14% {
    transform: scale(1.2);
  }
  28% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  82% {
    transform: scale(1.3);
  }
  96% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes tipjarAttention {
  0% {
    transform: translateY(-50%) rotate(-16deg);
  }
  100% {
    transform: translateY(-50%) rotate(345deg);
  }
}
@keyframes stripeAttention {
  0% {
    transform: translate(0, -50%);
    color: $color-white;
  }
  8% {
    color: transparent;
  }
  16% {
    color: transparent;
    transform: translate(0, -50%);
  }
  24% {
    transform: translate(100%, -50%);
    visibility: visible;
  }
  25% {
    visibility: hidden;
  }
  56% {
    visibility: hidden;
    transform: translate(-100%, -50%);
  }
  72% {
    visibility: visible;
    transform: translate(0, -50%);
    color: transparent;
  }
  100% {
    color: $color-white;
    transform: translate(0, -50%);
  }
}
@keyframes venmoAttention {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  14% {
    opacity: 0;
    transform: scale(0);
  }
  72% {
    transform: scale(0);
  }
  73% {
    opacity: 1;
    transform: scale(0);
  }
  82% {
    transform: scale(1.2);
  }
  92% {
    transform: scale(1);
  }
}
@keyframes paypalAttention {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  12% {
    opacity: 0;
    transform: scale(0);
  }
  76% {
    transform: scale(0);
  }
  77% {
    opacity: 1;
    transform: scale(0);
  }
  85% {
    transform: scale(1.2);
  }
  94% {
    transform: scale(1);
  }
}
</style>
